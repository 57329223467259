<!--
 * @Description: 
 * @Autor: winzhao
 * @Date: 2021-10-21 19:55:40
 * @LastEditors: winzhao
 * @LastEditTime: 2021-10-21 21:25:23
-->
<template>
  <div class="percentage-card">
    <div class="percentage-card__text">
      <div class="index">{{ cardIndex }}</div>
      <div class="label">{{ cardData.name }}</div>
      <div class="value">{{ cardData.value + '人' }}</div>
      <div class="percentage">{{ cardData.percentage + '%' }}</div>
    </div>
    <div class="percentage-card__progress">
      <el-progress :percentage="cardData.percentage" :color="progresColor" :show-text="false"></el-progress>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PecentageCard',
  props: {
    cardIndex: {
      type: Number,
      default: 0,
    },
    cardData: {
      type: Object,
      default: () => {
        return {}
      },
    },
    progresColor: {
      type: String,
      default: '#64c2f8',
    },
  },
}
</script>

<style lang="scss" scoped>
.percentage-card {
  width: 100%;
  height: 65px;
  padding-top: 5px;
  &__text {
    height: 50px;
    line-height: 50px;
    display: flex;
    flex-direction: row;
    color: #8e9aa9;
    font-size: 20px;
    .index {
      font-size: 14px;
      font-weight: bold;
    }
    .label {
      padding: 0 10px;
    }
    .value {
      flex: 1;
      font-size: 22px;
    }
    .percentage {
      color: #646d78;
    }
  }
  &__progress {
    height: 10px;
  }
}
</style>