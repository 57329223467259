<!--
 * @Description: 
 * @Autor: winzhao
 * @Date: 2021-10-21 21:43:13
 * @LastEditors: winzhao
 * @LastEditTime: 2021-10-21 22:32:12
-->
<template>
  <div class="company-card" :style="{ background: companyData.color }">
    <div class="company-card__left"></div>
    <div class="company-card__icon">
      <i :class="companyData.icon"></i>
    </div>
    <div class="company-card__division"></div>
    <div class="company-card__info">
      <div class="text">{{ companyData.name }}</div>
      <div class="bottom">
        <div class="value">{{ companyData.value }}</div>
        <div class="unit" :style="{ color: companyData.color }">{{ companyData.unit }}</div>
      </div>
    </div>
    <div class="company-card__right"></div>
  </div>
</template>

<script>
export default {
  name: 'CompanyCard',
  props: {
    companyData: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.company-card {
  width: 380px;
  height: 113px;
  padding-top: 5px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  &__left {
    position: absolute;
    width: 50px;
    height: 50px;
    left: -25px;
    border-radius: 50px;
    background: #fff;
  }
  &__right {
    position: absolute;
    width: 50px;
    height: 50px;
    right: -25px;
    border-radius: 50px;
    background: #fff;
  }
  &__icon {
    padding-left: 40px;
    padding-right: 10px;
    width: 80px;
    height: 80px;
    color: #fff;
    font-size: 65px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    i {
      width: 80px;
      height: 80px;
    }
  }
  &__division {
    width: 2px;
    height: 100px;
    background: #fff;
  }
  &__info {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    color: #fff;
    .text {
      font-size: 24px;
    }
    .bottom {
      height: 40px;
      line-height: 40px;
      display: flex;
      flex-direction: row;
      align-content: center;
      font-size: 20px;
      .value {
        padding-right: 20px;
      }
      .unit {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        background: #fff;
        text-align: center;
        font-weight: bold;
      }
    }
  }
}
</style>